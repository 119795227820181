import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import {Audit, formatDateAndTime} from '../../../context/function';
import SalaryTypeForm from "./salary-type-form";

function ManageSalaryType(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const columns = ["SN", "SALARY TYPE", "DATE CREATED", "CREATED BY", "ACTION"];
    const controller = new AbortController();

    const [formData, setFormData] = useState({
        salary_type_id: "",
        salary_name: "",
        inserted_by: props.loginData[0]?.employee_id,
    })


    const getData = async () => {
        const { signal } = controller.signal;
        await axios.get(`${API_URL}human-resources/salary-type/list`, login?.token, { signal })
            .then((res) => {
                if (res.data.length > 0) {
                    let rows = [];
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.salary_name,
                            formatDateAndTime(x.inserted_date, "date"),
                            x?.inserted_by_name + ` (${x?.StaffID})`,
                            <button className='btn btn-sm btn-primary'
                                    onClick={() => {
                                        setFormData({
                                            ...formData,
                                            salary_type_id: x.salary_type_id,
                                            salary_name: x.salary_name,
                                        })
                                        setOpenModal(true);
                                    }} >
                                <i className='fa fa-edit' />&nbsp; Edit
                            </button>
                        ])
                    })
                    setTableData(rows)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error(e.response.data)
            })
    }

    useEffect(()=>{
        getData();
        Audit(props.loginData[0]?.employee_id, 'Viewed Salary Type');
        return () => {
            controller.abort()
        }
    }, [])


    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const onOpenModal = (status) => {
        setFormData({
            ...formData,
            salary_type_id: "",
            salary_name: "",
        })
        setOpenModal(status);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (formData.salary_type_id === "") {
            await axios.post(`${API_URL}human-resources/salary-type/add`, formData, login?.token)
                .then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Salary type added successfully");
                        Audit(props.loginData[0]?.employee_id, 'Added Salary Type');
                        onOpenModal(false);
                        getData();
                    } else if (res.data.message === "exists") {
                        toast.warning("Salary type already exists");
                    } else {

                    }
                }).catch((e) => { toast.error(e.response.data) })
        } else {
            await axios.patch(`${API_URL}human-resources/salary-type/update`, formData, login?.token)
                .then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Salary type added successfully");
                        Audit(props.loginData[0]?.employee_id, 'Updated Salary Type');
                        onOpenModal(false);
                        getData();
                    }
                }).catch((e) => { toast.error(e.response.data) })
        }

    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Manage Salary Type"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Employee Management', link: '/' },
                                    { name: 'Manage Salary Type', link: '/employee-management/salary-type' }]}
                                button={<button className="btn btn-primary" onClick={() => { onOpenModal(true) }}> + Add Salary Type</button>}
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    title="Manage Salary Type">
                    <SalaryTypeForm
                        values={formData}
                        onChange={onChange}
                        onSubmit={onSubmit}
                    />
                </BootstrapModal>

                <ReportTable title={"Manage Salary Type"} columns={columns} data={tableData} height={"700px"} />

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageSalaryType);
