import React from "react";
export default function Loader() {
    return (
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            margin: '-25px 0 0 -25px'
        }}>
            <span className="spinner-border text-primary m-lg-5" role="status"/><br/>
            <span className="text-muted fs-6 fw-semibold mt-5">Loading...</span>
        </div>
    );
}