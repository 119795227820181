import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import {API_URL, HOTEL_ADDRESS, HOTEL_NAME} from '../../../context/connection-string';
import {Audit, currencyConverter, formatDateAndTime, moneyFormat} from '../../../context/function';
import ReportSidebar from "../../../component/report-sidebar/report-sidebar";
import "../../human-resources/salary-report/style.css"
function StaffMonthlySalary(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const header = ["S/N", "Staff Number", "Surname",  "OtherNames", "Net Pay", "Banker", "Account Number", "Sort Code"];
    const [department, setDepartment] = useState([])
    const [reportData, setReportData] = useState([])
    const [salary, setSalaryData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })

    const getData = async () => {

        await axios.get(`${API_URL}human-resources/report/salary-months`, login?.token).then((res) => {
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }

    const getSalaryReport = async (salary_month) => {

        if (salary_month === "") {

        } else {
            setIsSubmitting(true)
            await axios.get(`${API_URL}report/monthly-salary/data/${salary_month}`, login?.token)
                .then((res) => {
                    if (res.data.message === "success"){
                        let distinct_department =  [...new Set(res.data.Department.map(e=> e.department_id))];
                        setReportData(res.data.Staff)
                        setDepartment(res.data.Department)
                        setSalaryData(res.data.Salary)
                        setIsShow(true)
                    }

                    setIsSubmitting(false)

                }).catch((e) => {
                    setIsSubmitting(false)
                    setIsShow(false)
                    toast.error("error getting allowances")
                })
        }
    }

    useEffect(() => {
        getData();
        Audit(props.loginData[0]?.employee_id, 'Viewed Monthly Salary Report');
    }, [])


    const getGrossPay = (employee) => {
      const data  = salary.filter(e=>e.employee_id === employee && e.item_name === "Gross Pay");
      if (data.length > 0){
          return data[0].amount;
      }else {
          return 0
      }
    }

    const getNetPay = (employee) => {
      const data  = salary.filter(e=>e.employee_id === employee && e.item_name === "Net Pay");
      if (data.length > 0){
          return data[0].amount;
      }else {
          return 0
      }
    }

    const getTotalGrossPay = (paypoint) => {
      const data  = salary.filter(e=>e.Paypoint === paypoint?.toString() && e.item_name === "Gross Pay");
      if (data.length > 0){
          return data.map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0) ;
      }else {
          return 0
      }
    }

    const getTotalNetPay = (paypoint) => {
      const data  = salary.filter(e=>e.Paypoint === paypoint?.toString() && e.item_name === "Net Pay");
      if (data.length > 0){
          return data.map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0) ;
      }else {
          return 0
      }
    }

    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })
        getSalaryReport(val)
    }


    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Staff Verification List"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Report', link: '/report/dashboard' },
                                    { name: 'Staff Verification List', link: '/report/staff-verification-list' }]}
                />

                <div className="row">
                    <ReportSidebar/>
                    <div className="col-xl-10">
                        <div className=' mb-5 d-print-none col-md-10'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-12 pb-3">
                                        <div className="form-group">
                                            <label htmlFor="month_id">Select Salary Month</label>
                                            <input type="month" id="month_id"
                                                   className="form-control"
                                                   value={formData.month_id}
                                                   onChange={onChange}
                                                   max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                                   required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            isSubmitting ?
                                <Loader />
                                :
                                <div className="col-md-10">
                                    <div className="card">
                                        <div className="card-body">
                                            {
                                                isShow ?
                                                    <div className="show-tbl">
                                                        {
                                                            department.length > 0 && department.map((element, int) => {
                                                                return <div className="d-flex flex-column flex-xl-row margin-up break-after ">
                                                                    <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                                                                        <div className="mt-n1">
                                                                            <div
                                                                                className="d-flex flex-stack pb-10 d-flex  align-items-center  justify-content-center">
                                                                                <div style={{fontSize: "40px"}}
                                                                                     className="text-center text-gray-800">
                                                                                    <strong>{HOTEL_NAME}</strong>
                                                                                    <div
                                                                                        style={{marginTop: '-5px'}}
                                                                                        className="text-center fw-semibold fs-7 text-gray-600">
                                                                                    </div>
                                                                                    <h3 className="text-center text-gray-800 text-uppercase">STAFF MONTHLY GROSS/NET PAY FOR {formatDateAndTime(formData.month_id, 'month_and_year')} </h3>
                                                                                    <h3 className="text-center text-gray-800 text-uppercase mb-1 mt-2">DEPARTMENT: {element.department_name} </h3>
                                                                                </div>
                                                                            </div>
                                                                            <div className="m-0">
                                                                                <div
                                                                                    className="fw-bold fs-6 text-gray-600 mb-8 text-end">Date: {formatDateAndTime(formData.month_id, 'month_and_year')}</div>
                                                                                <div className="flex-grow-1">
                                                                                    <div
                                                                                        className="table-responsive border-bottom mb-9">
                                                                                        <table className="table table-bordered mb-5"
                                                                                               style={{
                                                                                                   border: '1px solid #cccccc',
                                                                                                   paddingLeft: '15px'
                                                                                               }}>
                                                                                            <thead>
                                                                                            <tr style={{
                                                                                                border: '1px solid #9c9897',
                                                                                                backgroundColor: '#cccccc'
                                                                                            }}>
                                                                                                <th><b>S/N</b></th>
                                                                                                <th><b>Staff ID</b></th>
                                                                                                <th><b>Staff Name</b></th>
                                                                                                <th><b>Designation</b></th>
                                                                                                <th><b>Gross Pay (₦)</b></th>
                                                                                                <th><b>Net Pay (₦)</b></th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            { reportData.filter(e => e?.pay_point === element?.department_id).length > 0 && reportData.filter(e => e?.pay_point === element?.department_id).map((item, index) => {
                                                                                                return (
                                                                                                    <tr key={index + int}
                                                                                                        style={{border: '1px solid #cccccc'}}>
                                                                                                        <td>{index + 1}</td>
                                                                                                        <td>{item.staff_id}</td>
                                                                                                        <td>{item.employee_name}</td>
                                                                                                        <td>{item.Designation}</td>
                                                                                                        <td>{moneyFormat(getGrossPay(item.employee_id))}</td>
                                                                                                        <td>{moneyFormat(getNetPay(item.employee_id))}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                            }
                                                                                            <tr
                                                                                                style={{border: '1px solid #cccccc'}}>
                                                                                                <td colSpan={4} style={{fontSize: '16px', textAlign: 'center'}}><b>TOTAL</b></td>
                                                                                                <td><b>{moneyFormat(getTotalGrossPay(element?.department_id))}</b></td>
                                                                                                <td><b>{moneyFormat(getTotalNetPay(element?.department_id))}</b></td>
                                                                                            </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        <div className="mt-4 mb-4">
                                                                                            <b>Additional Comment(s)</b>
                                                                                            <br/> <br/>
                                                                                            <hr/>

                                                                                            <br/>
                                                                                            <hr/>

                                                                                        </div>
                                                                                        <br/>
                                                                                        <div
                                                                                            className="d-flex align-content-center justify-content-between mt-5 mb-5">
                                                                                            <div style={{marginRight: '10px'}}>
                                                                                                _____________________________________________ <br/>
                                                                                                <b>HOD/DEAN</b>
                                                                                            </div>
                                                                                            <div>
                                                                                                _____________________________________________ <br/>
                                                                                                <b>Bursar</b>
                                                                                            </div>
                                                                                            <div style={{marginLeft: '10px'}}>
                                                                                                _____________________________________________ <br/>
                                                                                                <b>Vice-Chancellor</b>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/*<hr className="no-printing mt-5" style={{height: '8'}} />*/}
                                                                </div>
                                                            })
                                                        }
                                                        <div className="col-md-3 offset-sm-5 d-print-none">
                                                            <button
                                                                className="form-control btn btn-primary"
                                                                onClick={() => window.print()}>Print
                                                            </button>
                                                        </div>
                                                    </div>

                                                    :
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="currentColor"></path>
																		<path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="currentColor"></path>
																	</svg>
																</span>
                                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                            <div className="mb-3 mb-md-0 fw-semibold">
                                                                <h4 className="text-gray-900 fw-bold">Please select salary month to view report</h4>
                                                                {/*<div className="fs-6 text-gray-700 pe-7">Please select salary month to view report</div>*/}
                                                            </div>

                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(StaffMonthlySalary);
