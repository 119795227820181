import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import {Audit, formatDateAndTime} from '../../../context/function';

function AuditReport(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const columns = ["SN", "Employee", "Action", "Date Created"];
    const controller = new AbortController();
    const getData = async () => {
        const { signal } = controller.signal;
        await axios.get(`${API_URL}settings/audit-trail`, login?.token, { signal })
            .then((res) => {
                if (res.data.length > 0) {
                    let rows = [];
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.employee_name,
                            x.action,
                            formatDateAndTime(x.inserted_date, "date"),
                        ])
                    })
                    setTableData(rows)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error(e.response.data)
            })
    }

    useEffect(() => {
        getData()
        Audit(props.loginData[0]?.employee_id, 'Viewed Audit Trail List');
        return () => {
            controller.abort()
        }
    }, [])



    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Audit Trail/Log"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Employee Management', link: '/' },
                                    { name: 'Audit Trail/Log', link: '' }]}
                />

                <ReportTable title={"Audit Trail/Log"} columns={columns} data={tableData} height={"700px"} />

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AuditReport);
