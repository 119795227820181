import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import { API_URL } from "../../../context/connection-string";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
    Audit,
    currencyConverter,
    currentDate,
    formatDateAndTime,
    monthNames,
    num_of_days,
    Renewal_date
} from "../../../context/function";
import { DashboardPageTitle } from "./dashboard-page-title";
import Svg from "./svg";
import Chart from "react-google-charts";
import RenewalPopUp from "../../notice-pop-up/notice-pop-up";

function DashboardHumanResources({ loginData }) {
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState({
        totalRoom: 0, availableRoom: 0, checkInList: 0, checkOutList: 0, cancelledList: 0, latestBooking: 0,
        total_employees: 0, male_employees: 0, female_employees: 0, credit: 0, guests: 0, total_salary_paid: 0, employee_division: 0, employee_department: 0, employee_job_title: 0,
        schedule_today: 0, checked_id: 0, checked_out: 0, employee_log: 0, salary_status: 0, wallet_outstanding: 0, settings_count: 0, account_numbers: 0
    })
    const [employeeDistr, setEmployeeDistr] = useState([["Gender", "Employee"]])
    const [departmentData, setDepartmentData] = useState([["Department", "Employee"]])
    const [employeeLog, setEmployeeLog] = useState([["Employee", "Log"]])
    const [accountPayable, setAccountPayable] = useState([["Month", "Amount Expected", "Amount Paid", "Outstanding"]])

    const gender_list = ["Male", "Female"]

    const fetchRecord = async () => {
        await axios.get(`${API_URL}report/human-resources-dashboard`, loginData[0].token)
            .then(res => {
                // console.log(res.data.salary_status.length)

                const employees = res.data.total_employees;
                let _emp_data = [
                    ...employeeDistr
                ];
                if (employees.length > 0) {
                    gender_list.map(r => {
                        return _emp_data.push([r, employees.filter(e => e.gender === r).length])
                    })
                }
                setEmployeeDistr(_emp_data)

                const employee_list = res.data.total_employees;
                const departments = res.data.department_list;
                let department_data = [
                    ...departmentData
                ];
                if (departments.length > 0) {
                    departments.map(r => {
                        return department_data.push([r.department_name, employee_list.filter(e => e.department_id === r.department_id).length])
                    })
                }
                setDepartmentData(department_data)


                setStats({
                    ...stats,
                    total_employees: res.data.total_employees.length,
                    male_employees: res.data.total_employees?.filter(x => x.gender === "Male")?.length,
                    female_employees: res.data.total_employees?.filter(x => x.gender === "Female")?.length,
                    total_salary_paid: res.data.total_salary_paid[0]?.total_salary_paid,
                    employee_department: res.data.employee_department.length,
                    division: res.data.division.length,
                    employee_job_title: res.data.employee_job_title.length,
                    schedule_today: res.data.schedule_today.length,
                    checked_id: res.data.schedule_today?.filter(x => x.check_in_time !== null).length,
                    checked_out: res.data.schedule_today?.filter(x => x.check_out_time !== null).length,
                    salary_status: res.data.salary_status.length,
                    account_numbers: res.data.account_numbers[0].account_numbers,
                    settings_count: res.data.settings_count[0].settings_count,
                })
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }

    useState(() => {
        fetchRecord()
        Audit(loginData[0]?.employee_id, 'Viewed Payroll System Dashboard');
    }, [])

    return isLoading ? <Loader /> : (
        <>
            {/*{num_of_days(currentDate, Renewal_date) <= 30 ? <RenewalPopUp /> : ""}*/}
            <DashboardPageTitle title=" Payroll Manager" />
            <div className="row g-5 g-xl-12 pt-5">
                <div className="col-xl-6">
                    <span className="card bg-dark hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Svg />
                                    <div className="text-white fw-bold fs-1 mb-2 mt-5">{stats.total_employees}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Total Employees</div>
                                </div>
                                <div >
                                    <Svg />
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">
                                        Male : {stats.male_employees}
                                    </div>
                                    <div className="text-white fw-bold fs-2 mb-2">
                                        Female : {stats.female_employees}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>

                <div className="col-xl-6">
                    <span className="card bg-dark hoverable card-xl-stretch mb-xl-8" data-bs-theme="light" >
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Svg />

                                    <div className="text-white fw-bold fs-2 mt-5">Total Salary Paid</div>
                                    <div className="text-white fw-semibold fs-3x lh-1 ls-n2">
                                        {currencyConverter(stats.total_salary_paid)}
                                    </div>
                                </div>
                                <div className="card bg-dark hoverable shadow-lg p-3 mb-5 rounded card-xl-stretch mb-xl-8 p-5" data-bs-theme="light"  >
                                    <Svg />
                                    <div className="text-white fw-bold fs-2 mt-5">Salary Status ({formatDateAndTime(currentDate, "month_and_year")})</div>
                                    <div className="text-white fw-semibold fs-3x lh-1 ls-n2">
                                        {
                                            stats.salary_status > 0 ?
                                                <div className="btn-group">
                                                    <a to={`/#`} className="btn btn-sm btn-success btn-group">
                                                        <i className="fa fa-check-circle" />
                                                        Processed
                                                    </a>
                                                </div>
                                                :
                                                <span >
                                                    <div className="btn-group">
                                                        <span className="btn btn-sm btn-danger btn-group">
                                                            <i className="fa fa-check-circle" />
                                                            Not Processed
                                                        </span>
                                                        <Link to={`/human-resources/run-salary`} className="btn btn-sm btn-secondary btn-group">
                                                            <i className="fa fa-pen" /> Run Salary
                                                        </Link>
                                                    </div>
                                                </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>

                {
                    employeeDistr.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={employeeDistr}
                            options={{ title: "Staff Distribution By Gender", is3D: true }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

                {
                    departmentData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={departmentData}
                            options={{ title: "Staff Distribution By Division", is3D: true }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }
                <div className="col-xl-4">
                    <span className="card bg-dark hoverable mb-xl-8">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Svg/>
                                    <div className="text-white fw-bold fs-1 mb-2 mt-5">{stats.division}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Divisions</div>
                                </div>
                                <div>
                                    <Svg/>
                                    <div className="text-white fw-bold fs-1 mb-2 mt-5">{stats.employee_department}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Departments</div>
                                </div>
                            </div>
                        </div>
                    </span>
                    <span className="card bg-dark hoverable mb-xl-8">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Svg />
                                    <div className="text-white fw-bold fs-1 mb-2 mt-5">{stats.account_numbers}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Ledger Accounts</div>
                                </div>
                                <div className="align-content-lg-end" >
                                    <Svg />
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">{stats.settings_count}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Salary Settings</div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>



            </div>






        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};


export default connect(mapStateToProps, null)(DashboardHumanResources);
