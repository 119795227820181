import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

function GeneralSubMenu(props) {
    const [menuIDList, setMenuIDList] = useState([])


    useEffect(() => {
        if (props.permissionData.length > 0) {
            const menu_id_list = [
                ...new Set(props.permissionData.map((item) => item.menu_id)),
            ];
            setMenuIDList(menu_id_list)
        }
    }, [])

    return (
        <div className="header-navs d-flex align-items-stretch flex-stack h-lg-70px w-100 py-5 py-lg-0 overflow-hidden overflow-lg-visible" id="kt_header_navs">
            {/*begin::Container*/}
            <div className="d-lg-flex container-xxl w-100">
                {/*begin::Wrapper*/}
                <div className="d-lg-flex flex-column justify-content-lg-center w-100" id="kt_header_navs_wrapper">
                    {/*begin::Header tab content*/}
                    <div className="tab-content">
                        {
                            menuIDList.length > 0 &&
                                menuIDList.map((id, i) => {
                                    const sub_menu_list = props.permissionData.filter(p => p.menu_id === id);
                                    return <div key={i} className={`tab-pane fade ${i===0 ?? 'active show'}`} id={`menu-${id}`}>
                                        <div className="header-menu flex-column align-items-stretch flex-lg-row">
                                            <div className="menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-title-gray-700 menu-state-primary menu-arrow-gray-400 fw-semibold align-items-stretch flex-grow-1 px-2 px-lg-0" id="#kt_header_menu">
                                                {
                                                    sub_menu_list.length > 0 &&
                                                        sub_menu_list.map((sub, index) => {
                                                            return (
                                                                <div key={index} className="menu-item menu-lg-down-accordion me-0 me-lg-2">
                                                                    {/*begin:Menu link*/}
                                                                    <Link to={sub.sub_menu_link} className={"menu-link py-3"}>
                                                                        {sub.sub_menu_name}
                                                                    </Link>
                                                                </div>
                                                            )
                                                        })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })
                        }

                    </div>
                    {/*end::Header tab content*/}
                </div>
                {/*end::Wrapper*/}
            </div>
            {/*end::Container*/}
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        permissionData: state.PermissionDetails,
    };
};

export default connect(mapStateToProps, null)(GeneralSubMenu);
