import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import {Audit, formatDateAndTime, showAlert} from '../../../context/function';
import AllowancesAnddeductionsForm from './allowances-and-deductions-form';
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import {Await} from "react-router";
import PayrollSidebar from "../../../component/payroll-sidebar/payroll-sidebar";

function AllowancesAndDeductions(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [ledgerAccountsList, setLedgerAccountsList] = useState([])
    const [employeeList, setEmployeeList] = useState([]);
    const [employees, setEmployees] = useState([])
    const columns = ["S/N", "A&D ID", "EMPLOYEE", "POST TYPE", "ACCOUNT", "START", "END", "FREQUENCY", "AMOUNT", "STATUS", "ACTION"];

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        allowance_id: "", employee_id: "", post_type: "", ledger_account: "", start_date: "", end_date: "", frequency: "", amount: "", status: "",
        inserted_by: props.loginData[0]?.employee_id, selected_employees: []
    })

    const getData = async () => {
        await axios.get(`${API_URL}human-resources/employee/list/`, login?.token).then((res) => {
            let rows = []
            if (res.data.length > 0) {
                res.data.map((row) => {
                    rows.push({ value: row.employee_id, label: row.full_name + "- " + row.department_name + "- "+"(" + row.staff_id + ")"})
                });
            }
            setEmployees(rows);
            setEmployeeList(res.data);
        }).catch((e) => {
            toast.error(e.response.data)
        })

        let _ledger_Acc = []
        await axios.get(`${API_URL}human-resources/ledger-accounts/list`, login?.token).then((res) => {
            _ledger_Acc = res.data;
            setLedgerAccountsList(res.data);
        }).catch((e) => {
            toast.error(e.response.data)
        })

        await getEmployeeAllowanceDeductions(_ledger_Acc);
    }

    const getEmployeeAllowanceDeductions = async (data = []) => {
        await axios.get(`${API_URL}human-resources/allowances/list`, login?.token)
            .then((res) => {
                if (res.data.length > 0) {
                    let rows = [];
                    res.data.map((x, i) => {
                        const ledger_account = data?.filter(j => j.ledger_id === parseInt(x.ledger_account))[0]
                        rows.push([
                            i + 1,
                            x.allowance_id.toString().padStart(4, "0"),
                            x.employee_name + ` (${x.employee_id})`,
                            x.post_type,
                            ledger_account?.account_number + `(${ledger_account?.description})`,
                            formatDateAndTime(x.start_date, "date"),
                            formatDateAndTime(x.end_date, "date"),
                            x.frequency,
                            x.amount,
                            x.status,
                            < button className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        allowance_id: x.allowance_id,
                                        employee_id: x.employee_id,
                                        post_type: x.post_type,
                                        start_date: x.start_date,
                                        end_date: x.end_date,
                                        frequency: x.frequency,
                                        amount: x.amount,
                                        status: x.status,
                                        ledger_account: x.ledger_account
                                    })
                                    setOpenModal(true);
                                }
                                } >
                                <i className='fa fa-edit' /> &nbsp; Edit
                            </button >
                        ])
                    })
                    setTableData(rows)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error("error getting allowances")
            })
    }

    useEffect(() => {
        getData();
        Audit(props.loginData[0]?.employee_id, 'Viewed Staff Allowance and Deduction');
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })
    }


    const onOpenModal = (status) => {
        setIsSubmitting(false);
        setFormData({
            ...formData,
            allowance_id: "", employee_id: "", post_type: "", ledger_account: "", start_date: "", end_date: "", frequency: "", amount: "", status: "",
        })
        setOpenModal(status);
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        toast.info("please wait...")
        if (formData.allowance_id === "") {
            await axios.post(`${API_URL}human-resources/allowances/add`, formData, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        getData()
                        // getEmployeeAllowanceDeductions(ledgerAccountsList);
                        toast.success("allowance/deduction added successfully");
                        if(res.data.duplicates.length > 0) {
                            toast.info(`However, the following staff already have allowances set for the selected settings \n ${res.data.duplicates.join(",")}`)
                        }
                        Audit(props.loginData[0]?.employee_id, 'Added Staff Allowance and Deduction');
                        onOpenModal(false);
                    } else if (res.data.message === "exists") {
                        toast.warning(res.data.text);
                    } else {

                    }
                    setIsSubmitting(false)
                }).catch(() => {
                    setIsSubmitting(false);
                    toast.error(e.response.data)
                })
        } else {
            await axios.patch(`${API_URL}human-resources/allowances/update`, formData, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        getData()
                        // getEmployeeAllowanceDeductions(ledgerAccountsList);
                        toast.success("allowance/deduction added successfully");
                        onOpenModal(false);
                        Audit(props.loginData[0]?.employee_id, 'Updated Staff Allowance and Deduction');
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error(e.response.data)
                })
        }
    }
    const onMultiEdit = (e) => {
        setFormData({
            ...formData,
            selected_employees: e
        })
    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Allowances and Deductions"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/' },
                    { name: 'Allowances and Deductions', link: '/human-resources/manage-allowance-and-deduction' }]}
                    button={<button className="btn btn-primary" onClick={() => { onOpenModal(true); setIsSubmitting(false) }}> + Add Allowances/Deductions</button>}
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    title="Manage Allowances and Deductions">
                    <AllowancesAnddeductionsForm
                        employees={employees}
                        onMultiEdit={onMultiEdit}
                        values={formData}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                        employeeList={employeeList}
                        ledgerAccountsList={ledgerAccountsList}
                    />
                </BootstrapModal>
                <div className="row">
                    <PayrollSidebar/>
                    <div className="col-xl-10">
                        <ReportTable title={"Allowances and Deductions"} columns={columns} data={tableData} height={"700px"} />
                    </div>
                </div>

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AllowancesAndDeductions);
