import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import { API_URL } from '../../../context/connection-string';
import {Audit, currencyConverter, formatDateAndTime, moneyFormat} from '../../../context/function';
import ReportSidebar from "../../../component/report-sidebar/report-sidebar";
import AllowanceDataTable from "./data-table/allowance-data-table";

function AllowancesAndDeductionsReport(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const header = ["S/N", "Staff Number", "Surname", "OtherNames", "Department", "Amount (₦)"];
    const [reportData, setReportData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        month_id: "",
        report_type: "",
        inserted_by: props.loginData[0]?.employee_id,
        settings_type: ""
    })
    // let total = 0;
    const [salarySettings, setSalarySettings] = useState([])
    const [salarySettings2, setSalarySettings2] = useState([])
    const [settingsType, setsettingsType] = useState('')

    const getData = async () => {
        const { data } = await axios.get(`${API_URL}human-resources/allowances-deductions/list`, login?.token)
        setSalarySettings(data)

        await axios.get(`${API_URL}human-resources/report/salary-months`, login?.token).then((res) => {
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }
    const showTable = () => {

        try {
            return reportData.filter(e => e.amount > 0).map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index + 1}</td>
                        <td className="text-xs font-weight-bold">{item.staff_id}</td>
                        <td className="text-xs font-weight-bold">{item.Surname}</td>
                        <td className="text-xs font-weight-bold">{item.OtherNames}</td>
                        <td className="text-xs font-weight-bold">{item.Department}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.amount)}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }

    };

    const getTotal = () => {
        let total_amount = 0;
        reportData.filter(e => e.amount > 0).map((item, index) => {
            total_amount += item.amount;
        });
        return total_amount;
    }


    const getSalaryReport = async (salary_month, settings_type) => {
        const report_type = formData.report_type

        if (salary_month === "") {
        } else {
            setIsSubmitting(true)
            await axios.get(`${API_URL}report/payroll/allowances-and-deductions?salary_month=${salary_month}&report_type=${report_type}&settings_type=${settings_type}`, login?.token)
                .then((res) => {
                    if (res.data.length > 0) {
                        setReportData(res.data)
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error("error getting allowances")
                })
        }
    }

    useEffect(() => {
        getData();
        Audit(props.loginData[0]?.employee_id, 'Viewed Allowance and Deduction Report');
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        if (e.target.id === "report_type") {
            const sett_ = salarySettings?.filter(x => x.salary_type === val) ?? [];
            setsettingsType(val)
            setSalarySettings2(sett_)
        }
    }


    const onChangesettingsType = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        if (val !== "" && formData.month_id !== "") {
            getSalaryReport(formData.month_id, val)
        }

    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Staff Cooperative Report"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Report', link: '/report/dashboard' },
                    { name: 'Staff Cooperative Report', link: '/report/allowance-and-deduction-report' }]}
                />

                <div className="row">
                    <ReportSidebar />
                    <div className="col-xl-10">
                        <div className=' mb-5'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-4 pb-3">
                                        <div className="form-group ">
                                            <label htmlFor="month_id">Select Salary Month</label>
                                            <input type="month" id="month_id"
                                                className="form-control"
                                                value={formData.month_id}
                                                onChange={onChange}
                                                max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-md-4 pb-3">
                                        <div className="form-group ">
                                            <label htmlFor="month_id">Select Report Type</label>
                                            <select className="form-control" id="report_type" value={formData.report_type} onChange={onChange}>
                                                <option value="">Select Report Type</option>
                                                <option value="Allowance">Allowance</option>
                                                <option value="Deduction">Deduction</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        settingsType !== '' &&
                                        <div className="col-md-4 pb-3">
                                            <div className="form-group ">
                                                <label htmlFor="month_id">Select {settingsType} Type</label>
                                                <select className="form-control" id="settings_type" value={formData.settings_type} onChange={onChangesettingsType}>
                                                    <option value="">Select Settings Type</option>
                                                    {
                                                        salarySettings2.length > 0 &&
                                                        salarySettings2.map((x, i) => {
                                                            return (
                                                                <option key={i} value={x.item_name}>{x.item_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {
                            isSubmitting ?
                                <Loader />
                                :
                                <div className="">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <h3 className="card-title">{formData.settings_type ?? "Allowance and Deduction Report"}</h3>
                                                <AllowanceDataTable header={header} date={formatDateAndTime(formData.month_id, 'month_and_year')} caption={`Total Amount: ${currencyConverter(getTotal() ?? 0)}`} body={showTable()} title={`${formData.settings_type ?? "Report"}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AllowancesAndDeductionsReport);
