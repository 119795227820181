import axios from 'axios';
import { object } from 'prop-types';
import { async } from 'q';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import {Audit, formatDateAndTime} from '../../../context/function';
import IncomeEmolumentsForm from './income-and-emoluments-form';

function ManageIncomeEmoluments(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const columns = ["SN", "item name", "is taxable", "is Percentage", "is category", "DATE CREATED", "CREATED BY", "ACTION"];
    const controller = new AbortController();

    const [formData, setFormData] = useState({
        item_id: "",
        item_name: "",
        is_taxable: "",
        is_percentage: "",
        inserted_by: props.loginData[0]?.employee_id,
    })

    const getData = async () => {
        const { signal } = controller.signal;
        await axios.get(`${API_URL}human-resources/emolument/list`, login?.token, { signal })
            .then((res) => {
                if (res.data.length > 0) {
                    let rows = [];
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.item_name,
                            x.is_taxable === 1 ? 'Yes' :'No',
                            x.is_percentage === 1 ? 'Yes' :'No',
                            x.category,
                            formatDateAndTime(x.inserted_date, "date"),
                            x?.employee_name + ` (${x?.Name})`,
                            x.item_name.toLowerCase() === "pension" || x.item_name.toLowerCase() === "gratuity" ? "---" :
                            <button className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        item_id: x.item_id,
                                        item_name: x.item_name,
                                        is_taxable: x.is_taxable,
                                        is_percentage: x.is_percentage,
                                        is_basic: x.category
                                    })
                                    setOpenModal(true);
                                }} >
                                <i className='fa fa-edit' />&nbsp; Edit
                            </button>
                        ])
                    })
                    setTableData(rows)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error(e.response.data)
            })
    }


    useEffect(() => {
        getData();
        Audit(props.loginData[0]?.employee_id, 'Viewed Income and Emoluments Management');
        return () => {
            controller.abort()
        }
    }, [])


    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const onOpenModal = (status) => {
        setFormData({
            ...formData,
            itemt_id: "",
            item_name: "",
            is_taxable: "",
            is_basic: "",
        })
        setOpenModal(status);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (formData.is_percentage === ""){
            toast.error("Is percentage field cannot be empty");
            return false;
        }

        if (formData.itemt_id === "") {
            await axios.post(`${API_URL}human-resources/emolument/add`, formData, login?.token)
                .then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Emolument added successfully");
                        onOpenModal(false);
                        getData();
                        Audit(props.loginData[0]?.employee_id, 'Added Income and Emoluments Management');
                    } else if (res.data.message === "exists") {
                        toast.warning("Emolument already exists");
                    }else if (res.data.message === "cannot"){
                    toast.warning("You can only have one basic, transport and housing");
                    } else {
                        toast.error("Something went wrong");
                    }
                }).catch((e) => { toast.error(e.response.data) })
        } else {
            await axios.patch(`${API_URL}human-resources/emolument/update`, formData, login?.token)
                .then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Emolument added successfully");
                        onOpenModal(false);
                        getData();
                        Audit(props.loginData[0]?.employee_id, 'Updated Income and Emoluments Management');
                    } else if (res.data.message === "cannot"){
                        toast.warning("You can only have one basic, transport and housing");
                    }
                }).catch((e) => { toast.error(e.response.data) })
        }

    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Income and Emoluments Management"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/' },
                    { name: 'Income and Emoluments Management', link: '/human-resources/income-and-emoluments' }]}
                    button={<button className="btn btn-primary" onClick={() => {
                        onOpenModal(true)
                    }}>Add Income and Emoluments</button>}
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    title="Manage Income and Emolument">
                    <IncomeEmolumentsForm
                        values={formData}
                        onChange={onChange}
                        onSubmit={onSubmit}
                    />
                </BootstrapModal>

                <ReportTable title={"Income and Emoluments"} columns={columns} data={tableData} height={"700px"} />

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageIncomeEmoluments);
