import React, {useState} from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../action/set-action-details";
import Logo from "../../assets/img/logoo-.png";
import {API_URL, HOTEL_NAME} from "../../context/connection-string";
import {Link} from "react-router-dom";
import {Audit, encryptData} from "../../context/function";
import {toast} from "react-toastify";
import axios from "axios";

function ForgotPassword(props) {
    const [loginData, setLoginData] = useState({
        email_address: "",
        password: ""
    })


    const onHandleChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.id]: e.target.id === 'password' ? encryptData(e.target.value.trim()) : e.target.value.trim()
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (loginData.email_address === '') {
            toast.error("Please enter your registered email address");
            return false;
        }
        const token = "pa" + Math.floor(Math.random() * 999999999);
        toast.info("Please wait while trying to log you in...")
        await axios.patch(`${API_URL}login/forget_password/add_token/${loginData.email_address}`, { token: encryptData(token) })
            .then(res => {
                if (res.data.message === "success") {
                    toast.success('An email have been sent to your mailbox, please check and proceed.')
                } else if (res.data.message === "no email") {
                    toast.error('Sorry!, your email address is not registered')
                } else {
                    toast.error("Something went wrong. Please try again.")
                }
            })
            .catch(e => {
                console.log(e)
                toast.error("Please check your internet connection and try again!")
            })

    }
    return (
        <>
            <div className="d-flex flex-column flex-root">
                {/*begin::Authentication - Sign-in */}
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    {/*begin::Aside*/}
                    <div className="d-flex flex-lg-row-fluid">
                        {/*begin::Content*/}
                        <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
                            {/*begin::Image*/}
                            <img className="theme-light-show mx-auto mw-100 w-150px w-lg-400px mb-10 mb-lg-20" src={Logo} alt={HOTEL_NAME} />
                            {/*end::Image*/}
                            {/*begin::Title*/}
                            {/* <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">{HOTEL_NAME} (Bursary Division)</h1> */}
                            {/*end::Title*/}
                            {/*begin::Text*/}
                            {/* <div className="text-gray-600 fs-base text-center fw-semibold">{HOTEL_CAPTION}</div> */}
                            {/*end::Text*/}
                        </div>
                        {/*end::Content*/}
                    </div>
                    {/*begin::Aside*/}
                    {/*begin::Body*/}
                    <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
                        {/*begin::Wrapper*/}
                        <div className="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                            {/*begin::Content*/}
                            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
                                {/*begin::Wrapper*/}
                                <div className="d-flex flex-center flex-column-fluid pb-15 pb-lg-20">
                                    {/*begin::Form*/}
                                    <form onSubmit={onSubmit} className="form w-100" noValidate="novalidate" id="kt_sign_in_form">
                                        {/*begin::Heading*/}
                                        <div className="text-center mb-11">
                                            {/*begin::Title*/}
                                            <h1 className="text-dark fw-bolder mb-3">Forgot Password</h1>
                                            <label>Enter your email address to reset your password</label>
                                            {/*end::Title*/}
                                        </div>
                                        {/*begin::Heading*/}
                                        {/*begin::Input group=*/}
                                        <div className="fv-row mb-8">
                                            {/*begin::Email*/}
                                            <input type="email" placeholder="Employee Email Address" id="email_address" onChange={onHandleChange} autoComplete="off"
                                                   className="form-control bg-transparent" />
                                            {/*end::Email*/}
                                        </div>

                                        {/*end::Wrapper*/}
                                        {/*begin::Submit button*/}
                                        <div className="d-grid mb-10">
                                            {
                                                // currentDate > Renewal_date ? <div className="col-md-12 text-center alert alert-danger">Please Renew your Subscription</div> :

                                                <button type="submit" id="kt_sign_in_submit" className="btn btn-primary">
                                                    {/*begin::Indicator label*/}
                                                    <span className="indicator-label">Submit</span>
                                                    {/*end::Indicator label*/}
                                                </button>
                                            }
                                        </div>
                                        {/*end::Submit button*/}
                                    </form>
                                    {/*end::Form*/}
                                </div>
                                {/*end::Wrapper*/}
                            </div>
                            {/*end::Content*/}
                        </div>
                        {/*end::Wrapper*/}
                    </div>
                    {/*end::Body*/}
                </div>
                {/*end::Authentication - Sign-in*/}
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);