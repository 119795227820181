import React, {useEffect, useState} from "react";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled, { ThemeProvider }  from 'styled-components';
const theme = {
    colors: {
        primary: '#007bff',
        secondary: '#6c757d',
        white: '#fff',
        black: '#1c1c1e',
    },
};

export function GeneralMobileHeader(props) {
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        if (props.permissionData.length > 0) {
            const menu_key = "menu_name";
            const menu_list = [
                ...new Map(props.permissionData.map(menu =>
                    [menu[menu_key], menu])).values()
            ];
            setMenuList( menu_list)
        }
    },[])

    return (
        <ThemeProvider theme={theme}>
            <StyledNavbar bg="light" expand="lg">
                <StyledNav className="mr-auto">
                    {
                        menuList.length > 0 &&
                            menuList.map((menu, index) => {
                                const sub_menu_list = props.permissionData.filter(p => p.menu_id === menu.menu_id);

                                return (
                                    <StyledNavDropdown key={index} title={menu.menu_name}>
                                        {
                                            sub_menu_list.length > 0 &&
                                                sub_menu_list.map((sub, i) => {
                                                    return (
                                                        <NavDropdown.Item key={i} onClick={() => {props.onClose(false)}}>
                                                            <Link to={sub.sub_menu_link}>{sub.sub_menu_name}</Link>
                                                        </NavDropdown.Item>
                                                    )
                                                })
                                        }
                                    </StyledNavDropdown>
                                )
                            })
                    }


                </StyledNav>
            </StyledNavbar>
        </ThemeProvider>
    )
};
const StyledNavbar = styled(Navbar)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
`;

const StyledNav = styled(Nav)`
  .nav-link {
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.2rem;
    font-weight: 500;
    margin-right: 1.5rem;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
`;

const StyledNavDropdown = styled(NavDropdown)`
  .dropdown-toggle::after {
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;
    border-top: 0.3rem solid;
    border-right: 0.3rem solid transparent;
    border-left: 0.3rem solid transparent;
    content: '';
  }

  .dropdown-toggle.show::before {
    transform: rotate(180deg);
  }

  .dropdown-item {
    a {
      color: black !important;
      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
    &:focus, &:active {
      background-color: transparent;
    }
  }
`;