import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import {Audit, formatDateAndTime} from '../../../context/function';
import TitlesForm from './titles-form';

function ManageTitles(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const columns = ["SN", "Title ID", "Title NAME", "DATE CREATED", "CREATED BY", "ACTION"];
    const controller = new AbortController();

    const [formData, setFormData] = useState({
        title_id: "",
        title: "",
        hod: "",
        inserted_by: props.loginData[0]?.employee_id,
    })
    const getTitles = async () => {
        getData()
    }

    const getData = async (data = []) => {
        const { signal } = controller.signal;
        await axios.get(`${API_URL}human-resources/titles/list`, login?.token, { signal })
            .then((res) => {
                if (res.data.length > 0) {
                    let rows = [];
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.title_id.toString().padStart(4, "0"),
                            x.title,
                            formatDateAndTime(x.inserted_date, "date"),
                            x?.inserted_by_name + ` (${x?.StaffID})`,
                            <button className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        title_id: x.title_id,
                                        title: x.title,
                                    })
                                    setOpenModal(true);
                                }} >
                                <i className='fa fa-edit' />&nbsp; Edit
                            </button>
                        ])
                    })
                    setTableData(rows)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error(e.response.data)
            })
    }

    useEffect(() => {
        getTitles();
        Audit(props.loginData[0]?.employee_id, 'Viewed Job Title');
        return () => {
            controller.abort()
        }
    }, [])


    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const onOpenModal = (status) => {
        setFormData({
            ...formData,
            title_id: "",
            title: "",
        })
        setOpenModal(status);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (formData.title_id === "") {
            await axios.post(`${API_URL}human-resources/titles/add`, formData, login?.token)
                .then((res) => {
                    if (res.data.message === "success") {
                        toast.success("title added successfully");
                        onOpenModal(false);
                        getData();
                        Audit(props.loginData[0]?.employee_id, 'Added Job Title');
                    } else if (res.data.message === "exists") {
                        toast.warning("title name already exists");
                    } else {

                    }
                }).catch((e) => { toast.error(e.response.data) })
        } else {
            await axios.patch(`${API_URL}human-resources/titles/update`, formData, login?.token)
                .then((res) => {
                    if (res.data.message === "success") {
                        toast.success("title updated successfully");
                        Audit(props.loginData[0]?.employee_id, 'Updated Job Title');
                        onOpenModal(false);
                        getData();
                    }
                }).catch((e) => { toast.error(e.response.data) })
        }

    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Titles Management"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/' },
                    { name: 'Titles Management', link: '/human-resources/job-title' }]}
                    button={<button className="btn btn-primary" onClick={() => { onOpenModal(true) }}>+ Add Title</button>}
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    title="Manage Title">
                    <TitlesForm
                        values={formData}
                        onChange={onChange}
                        onSubmit={onSubmit}
                    />
                </BootstrapModal>

                <ReportTable title={"Manage Title"} columns={columns} data={tableData} height={"700px"} />

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageTitles);
